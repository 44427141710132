<template>
  <div
    class="register-component"
    uk-scrollspy="cls: uk-animation-slide-bottom uk-animation-slow;">
    <h4 class="uk-card-title uk-center uk-text-center">
      <span class="uk-logo uk-center uk-text-center">[Company logo]</span>
    </h4>

    <div class="uk-card-body uk-padding-remove-bottom">
      <h3 class="uk-center uk-text-center">
        <slot name="subTitle">{{ ucFirst(safeTranslate("user.registerTitle")) }}</slot>
      </h3>

      <div class="form-wrapper">
        <slot name="form">
          <base-form
            v-model="registrationData"
            action="/user/register"
            method="post"
            form-path="form/user-register"
            :config="finalFormConfig"
            :behaviours="{ ajaxifySmart: { callback: ourFormCallback } }"
            @form:submitSuccess="afterRegister"></base-form>
        </slot>
      </div>
      <div class="form-footer-links uk-flex uk-flex-between">
        <slot name="formFooterLinks">
          <div class="link-wrap uk-flex uk-flex-between">
            <router-link to="/" class="uk-text-meta">{{
              ucFirst(safeTranslate("core.backHome"))
            }}</router-link>
            <router-link :to="{ name: 'user-login' }" class="uk-text-meta">
              {{ ucFirst(safeTranslate("user.haveAccountLogin")) }}
            </router-link>
          </div>
        </slot>
      </div>
    </div>

    <spinner
      v-if="isFetchingProfile"
      overlay="fixed"
      :text="safeTranslate('user.loginInProgress')"></spinner>
  </div>
</template>

<script>
import { useStore } from "vuex";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";

import _ from "lodash/object";

export default {
  props: {
    /**
     * Where to redirect after registration
     * False -> no redirect
     **/
    registerRedirect: {
      type: [String, Boolean],
      default: "/",
    },
    formConfig : {
      type: Object,
      default: {},
    }
  },
  emits: ["register:registration-complete"],
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props, useStore());

    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  data: function () {
    return {
      temp: false,
      asyncData: {
        // temp: 'test'
      },
      formReady: false,
      registrationData: {},
      finalFormConfig: {
        ..._.merge({}, this.formConfig )
      },
    };
  },
  computed: {
    isFetchingProfile() {
      return this.$store.getters["user/fetchingProfile"];
    },
  },
  created() {},
  mounted() {
    //   setTimeout(() => {this.formReady = true}, 2000);
  },
  methods: {
    ourFormCallback() {
      console.log("form callback");
    },
    testMethod(test) {
      console.log("test method!", test);
    },
    afterRegister(eventData) {
      let creds = {
        email: this.registrationData.email,
        password: this.registrationData.password,
      };
      this.login(creds);
    },
    async login(creds) {
      let loginResult = await this.$store.dispatch("user/authenticate", {
        type: "emailPassword",
        ...creds,
      });

      if (!loginResult) {
        this.$saffron.ui.notification(
          this.safeTranslate("user.registrationOkButCantLogin"),
          "danger"
        );
        return false;
      }

      let result = await this.$store.dispatch("user/updateUserProfile");

      if (result === false) {
        this.$saffron.ui.notification(
          this.safeTranslate("user.fetchProfileException"),
          "danger"
        );
        return false;
      }

      this.$emit[("register:registration-complete", { userProfile: result.data })];
      if (this.registerRedirect) {
        this.$router.push(this.registerRedirect);
      }

      return true;
    },
  },
};
</script>

<style scoped lang="scss">
// TODO: mixin imports, especially directional
/*

$breakpoint-small: 640px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;
 */

.form-wrapper {
  position: relative;
}

.register-component {
  max-width: calc(90vw - 60px);
  width: 550px;
  flex: 550px;

  @media screen and (max-width: 640px) {
    max-width: calc(90vw - 60px);
    width: 80vw;
    flex: 80vw;
  }

  @media screen and (max-width: 400px) {
    max-width: calc(90vw - 20px);
    width: 90vw;
    flex: 90vw;
  }

  .uk-card-body {
    padding-top: 0;
    @media screen and (max-width: 400px) {
      padding: 20px 10px;
    }
  }

  .form-footer-links {
    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }

    .link-wrap {
      width: 100%;
      flex: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
